export const LeftArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M7.33404 16.822L15.6242 8.53192L15.6242 13.5222C15.6226 13.6107 15.6387 13.6986 15.6715 13.7809C15.7043 13.8631 15.7532 13.9379 15.8152 14.0011C15.8773 14.0642 15.9513 14.1144 16.0329 14.1486C16.1146 14.1828 16.2022 14.2005 16.2907 14.2005C16.3793 14.2005 16.4669 14.1828 16.5485 14.1486C16.6302 14.1144 16.7042 14.0642 16.7662 14.0011C16.8283 13.9379 16.8771 13.8631 16.9099 13.7809C16.9427 13.6986 16.9588 13.6107 16.9573 13.5222L16.9573 6.92255C16.9573 6.74577 16.887 6.57624 16.762 6.45124C16.637 6.32624 16.4675 6.25601 16.2907 6.25598L9.69106 6.25598C9.51629 6.25902 9.34969 6.33058 9.22717 6.45526C9.10464 6.57993 9.03598 6.74774 9.03598 6.92255C9.03598 7.09735 9.10464 7.26516 9.22717 7.38984C9.34969 7.51451 9.51629 7.58608 9.69107 7.58911L14.6814 7.58911L6.39123 15.8792C6.26621 16.0043 6.19597 16.1738 6.19597 16.3506C6.19597 16.5274 6.26621 16.697 6.39123 16.822C6.51626 16.9471 6.68583 17.0173 6.86264 17.0173C7.03945 17.0173 7.20902 16.9471 7.33404 16.822Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};
