export const StartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_123_719)">
        <path
          d="M10.9987 20.1663C16.0613 20.1663 20.1654 16.0623 20.1654 10.9997C20.1654 5.93706 16.0613 1.83301 10.9987 1.83301C5.93609 1.83301 1.83203 5.93706 1.83203 10.9997C1.83203 16.0623 5.93609 20.1663 10.9987 20.1663Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16797 13.75V8.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.832 13.75V8.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_719">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
