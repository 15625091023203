import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import { LeftArrowIcon } from '../icons/LeftArrowIcon';
import { StartIcon } from '../icons/StartIcon';
import { StopIcon } from '../icons/StopIcon';

import { isMobile as detectMobile } from 'react-device-detect';
import IdentifierService from '../../helpers/services/identifier.service';
import { PushSCEventsToGTM } from '../../helpers/utilities/gtm';
import { SellMyCarPathname } from '../../types/constants';
import { SellMyCarEvents } from '../../types/gtm';
import 'swiper/css';
import 'swiper/css/pagination';

type PropType = {
  slides: any[];
  direction: string;
  isLoading?: boolean;
};

const customLoader = ({ src, width, quality }: any) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const ImageCarousel: React.FC<PropType> = (props) => {
  const router = useRouter();
  const { slides, direction, isLoading } = props;
  const [swiperValue, setSwiperValue] = useState<HTMLLinkElement | any>(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const [isMobile, setIsMobile] = useState<boolean | null>(null);

  useEffect(() => {
    setIsMobile(detectMobile); // Set the isMobile value once the component has mounted
  }, []);

  function updateVisitedBullets(slides: Number[], activeIndex: Number) {
    var bullets = document.querySelectorAll('.swiper-pagination-bullet');
    bullets.forEach((bullet, index) => {
      bullet.classList.remove(
        'swiper-pagination-bullet-visited',
        'swiper-pagination-bullet-active'
      );
      if (slides.indexOf(index) > -1) {
        bullet.classList.remove('swiper-pagination-bullet-active');
        bullet.classList.add('swiper-pagination-bullet-visited');
      }
      if (index === activeIndex) {
        bullet.classList.remove('swiper-pagination-bullet-visited');
        bullet.classList.add('swiper-pagination-bullet-active');
      }
    });
  }

  useEffect(() => {
    var visitedSlides: Number[] = [];
    var swiperBanner = new Swiper('.swiper-container', {
      spaceBetween: 0,
      speed: 2000,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      modules: [Autoplay, Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: false,
      },
      on: {
        slideChange: (swiperCore: any) => {
          const { realIndex } = swiperCore;
          if (realIndex == 0) {
            visitedSlides = [];
          }
          visitedSlides.push(realIndex);
          updateVisitedBullets(visitedSlides, realIndex);
        },
      },
    });
    // swiperBanner?.autoplay?.stop();
    setSwiperValue(swiperBanner);
    return () => {
      swiperBanner?.destroy();
    };
  }, [direction, slides]);

  return (
    <>
      <div className="relative px-6 pt-4 lg:p-0 flex justify-center items-center w-auto lg:w-[1440px] mx-auto">
        <div className="swiper ">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {slides.length > 0 ? (
                slides.map((item, index) => {
                  const mobileImageSrc = `${process.env.NEXT_PUBLIC_CMS_API_URL}${item?.attributes?.MobileImage?.data?.attributes?.url}`;
                  const desktopImageSrc = `${process.env.NEXT_PUBLIC_CMS_API_URL}${item?.attributes?.Image?.data?.attributes?.url}`;

                  return (
                    <div
                      className="swiper-slide relative"
                      key={index}
                      dir={direction}
                    >
                      <figure>
                        {isMobile ? (
                          <div className="relative  flex lg:hidden justify-center items-center">
                            <picture>
                              <img
                                alt={item?.attributes?.Title}
                                src={mobileImageSrc}
                                width={656}
                                height={384}
                                className="rounded-[16px] lg:rounded-none"
                              />
                            </picture>
                          </div>
                        ) : (
                          <div className="relative min-h-[540px] justify-center items-center hidden lg:flex">
                            <picture>
                              <img
                                alt={item?.attributes?.Title}
                                src={desktopImageSrc}
                                width={1440}
                                height={540}
                                className="rounded-[16px] lg:rounded-none"
                              />
                            </picture>
                          </div>
                        )}

                        <div className="top-10 ltr:left-8 rtl:right-8 ltr:md:left-28 rtl:md:right-28 lg:top-28 rtl:lg:right-72 ltr:lg:left-72 absolute">
                          <p className="text-[1.5rem] lg:text-[2.5rem] text-white font-semibold">
                            {item?.attributes?.Title}
                          </p>
                          <div className="text-[1.5rem] lg:text-[2.5rem] text-white font-semibold">
                            {item?.attributes?.Subtitle}
                          </div>
                          <div className="text-lg lg:text-xl text-white font-semibold mt-2 lg:mt-4">
                            {item?.attributes?.description}{' '}
                          </div>

                          {item?.attributes?.CtaText && (
                            <button
                              tabIndex={69 + (index + 1)}
                              onClick={async () => {
                                if (
                                  item?.attributes?.Link &&
                                  item?.attributes?.Link.includes(
                                    SellMyCarPathname
                                  )
                                ) {
                                  const clarityId =
                                    await IdentifierService.getClarityUserId();
                                  PushSCEventsToGTM(
                                    SellMyCarEvents.CTA_CLICKED,
                                    {
                                      clarity_id: clarityId,
                                      button_location: 'Homepage Banner',
                                    }
                                  );
                                }
                                router.push(item?.attributes?.Link);
                              }}
                              title={item?.attributes?.CtaText}
                              onMouseEnter={() => {
                                if (swiperValue) {
                                  setIsPlaying(false);
                                  swiperValue?.autoplay?.stop();
                                }
                              }}
                              onMouseLeave={() => {
                                if (swiperValue) {
                                  setIsPlaying(false);
                                  swiperValue?.autoplay?.start();
                                }
                              }}
                              type="button"
                              className="rounded-full lg:rounded-none h-14 min-w-36 px-4 bg-[#E02E01] active:bg-red-700 text-white text-lg outline-none focus:outline-none mt-[20px] lg:mt-[30px] flex items-center justify-center"
                            >
                              {item?.attributes?.CtaText} <LeftArrowIcon />
                            </button>
                          )}
                        </div>
                      </figure>
                    </div>
                  );
                })
              ) : (
                <div
                  className={`bg-white overflow-hidden shadow-[0_1px_8px_0px_rgba(0,0,0,0.1)] h-[540px]`}
                ></div>
              )}
            </div>
          </div>
        </div>

        <div className="custom-pagination">
          <div className="swiper-pagination"></div>
          {slides.length > 0 && (
            <button
              id="al"
              tabIndex={68}
              aria-label="swiperplay"
              className={`z-10 absolute ${
                direction === 'rtl' ? 'mr-[2px]' : 'left-1'
              } top-2 hidden lg:block`}
              type="button"
              onClick={() => {
                setIsPlaying(!isPlaying);
                isPlaying
                  ? swiperValue?.autoplay?.stop()
                  : swiperValue?.autoplay?.start();
              }}
            >
              {isPlaying ? <StartIcon /> : <StopIcon />}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ImageCarousel);
